// @flow

import HtmlHead from "../components/HtmlHead"
import Link from '../components/Link';
import React from "react"
import {Result} from 'antd';

const NotFoundView = (props: Object) => {
  return (
    <>
      <HtmlHead title={`404 Page Not Found | PostSeal`} />
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<Link className={'ant-btn ant-btn-primary ant-btn-round ant-btn-lg'} to='/'>Home</Link>}
      />
    </>
  );
};

export default NotFoundView;
 
